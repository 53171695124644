import { validateAnalysisName } from "@app/analysis/basics/state/basics.actions";
import { getBasicsValidation } from "@app/analysis/basics/state/basics.selectors";
import {
    setInitialAnalysisConfiguration,
    stateToProjectData,
    validateFeatureFlags,
} from "@app/analysis/state/analysisConfiguration.actions";
import { getCopyAnalysisValidation } from "@app/analysis/state/analysisConfiguration.helpers";
import { getAvailableDataMonthsByMode } from "@app/analysis/state/analysisConfiguration.selectors";
import {
    saveAnalysisSuccess,
    setAnalysisActionError,
    setAnalysisValidation,
    setProjectFolder,
} from "@app/analysis/state/general/general.actions";
import {
    getAnalysisValidation,
    getGMCVDFeatureState,
} from "@app/analysis/state/general/general.selectors";
import { cancelAllEditDataPeriods } from "@app/analysis/timePeriods/state/timePeriods.actions";
import type { TAppDispatch } from "@app/store";
import type { TGetState } from "@app/store/root.reducer";
import { getIsTMCAnalysis } from "@common/helpers/analysis";
import { AnalysesApiService } from "@common/services/server/analysesApi.service";
import type { IAnalysis } from "@common/services/server/analysesApi.types";
import { actions } from "./draftAnalysis.reducer";
import { getDraftState, shouldUpdateDraft } from "./draftAnalysis.selectors";

type TAnalysisData = {
    project_id: number;
} & Partial<IAnalysis>;

export const { setDraftConfig, resetDraftConfig } = actions;

export const updateDraft = () => (dispatch: TAppDispatch, getState: TGetState) => {
    const state = getState();

    return AnalysesApiService.updateDraft(
        getDraftState(state).draftId,
        //@ts-ignore
        stateToProjectData({ state, isSavingToDraft: true }),
    )
        .then(() => {
            dispatch(saveAnalysisSuccess(true));
            dispatch(setProjectFolder(null));
        })
        .catch(error => {
            const message = error?.response?.data?.message || error.message;
            dispatch(setAnalysisActionError(message));
        });
};

export const validateDraft = () => (dispatch: TAppDispatch, getState: TGetState) => {
    dispatch(validateAnalysisName());

    // Computing analysis validation
    const newState = getState();
    const { isInvalid: isBasicsInvalid, fields: basicsFields } = getBasicsValidation(newState);
    dispatch(setAnalysisValidation(isBasicsInvalid));

    if (isBasicsInvalid) {
        const errors = Object.keys(basicsFields)
            .filter(fieldName => basicsFields[fieldName].reasons)
            .reduce(
                (acc, fieldName) => [...acc, ...basicsFields[fieldName].reasons],
                [] as Array<string>,
            );
        dispatch(setAnalysisActionError(errors.join(";")));
    }
};

export const saveDraft = () => (dispatch: TAppDispatch, getState: TGetState) => {
    // Cancel any editing before validating.
    dispatch(cancelAllEditDataPeriods());
    dispatch(validateDraft());

    const newState = getState();
    const isInvalidDraft = getAnalysisValidation(newState).isInvalid;
    if (isInvalidDraft) {
        return Promise.reject();
    }
    if (shouldUpdateDraft(getState())) {
        return dispatch(updateDraft());
    } else {
        return AnalysesApiService.saveDraft(
            //@ts-ignore
            stateToProjectData({ state: getState(), isSavingToDraft: true }),
        )
            .then(() => {
                dispatch(saveAnalysisSuccess(true));
            })
            .catch(error => {
                const message = error?.response?.data?.message || error.message;
                dispatch(setAnalysisActionError(message));
                return Promise.reject();
            });
    }
};

// Used in copy analysis and open draft actions
export const getDraft =
    (analysisData: TAnalysisData, isCopy: boolean) =>
    (dispatch: TAppDispatch, getState: TGetState) => {
        return AnalysesApiService.getDraft(analysisData.project_id)
            .then(({ draft }) =>
                //@ts-ignore
                dispatch(validateFeatureFlags(draft)).then(errors => {
                    if (!errors.length) {
                        const state = getState();
                        const availableDataMonths = getAvailableDataMonthsByMode(state);
                        const gmCVDFeatureState = getGMCVDFeatureState(state);
                        const analysisValidation = getCopyAnalysisValidation(draft, {
                            availableDataMonths,
                            gmCVDFeatureState,
                        });
                        const isCopyTMCDraft = isCopy && getIsTMCAnalysis(draft.project_type);

                        if (!isCopy) {
                            dispatch(setDraftConfig(analysisData.project_id));
                        }

                        // Will be handled in openDraft or copyAnalysis
                        if (analysisValidation.hasWarnings || isCopyTMCDraft) {
                            return Promise.resolve({
                                analysisData: draft,
                                analysisValidation,
                                isDraft: true,
                            });
                        }

                        dispatch(
                            setInitialAnalysisConfiguration({
                                analysisData: draft,
                                isCopy,
                                isDraft: true,
                            }),
                        );
                        return Promise.resolve();
                    }
                    return Promise.reject(errors);
                }),
            )
            .catch(error => {
                const messages = Array.isArray(error)
                    ? error
                    : [error?.response?.data?.message || error.message];

                dispatch(setAnalysisActionError(messages));
                return Promise.reject(messages);
            });
    };

export const finishDraft = (analysisData: TAnalysisData) => (dispatch: TAppDispatch) => {
    return dispatch(getDraft(analysisData, false /*isCopy*/));
};
